import { CHANGE_UI_THEME, ADD_NOTIFICATION } from "./App.constants";
import { UIAction, initialUIState } from "./App.types";

const AppReducer = (state = initialUIState, action: UIAction) => {
  switch (action.type) {
    case CHANGE_UI_THEME:
      return {
        ...state,
        uiTheme: action.payload
      }
    case ADD_NOTIFICATION:
      return {
        ...state,
          notification: action.payload
      }
    default:
      return state;
  }
}

export default AppReducer;
