import { ADD_NOTIFICATION, CHANGE_UI_THEME } from "./App.constants";

export interface Notification {
  key?: number,
  type: string,
  message: string,
  delay?: number,
  style?: object,
  className?: string
}

export interface UI {
  uiTheme: string,
  notification?: Notification
}

export type UIAction =
{ type: typeof CHANGE_UI_THEME,
  payload: string
} |
{
  type: typeof ADD_NOTIFICATION,
  payload: any
} 

export const initialUIState:UI = {
  uiTheme: "dark"
}

/** UI context */

export interface UIContextInterface {
  ui: UI,
  dispatchUI? : any
}

export const UIContextState: UIContextInterface = {
  ui: initialUIState
}

export interface nameValuePair {
  name: string,
  value: string
}
