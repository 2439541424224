import React from "react";
import { Routes, Route } from "react-router-dom";
/** Contexts */
import { UIContext, UIState } from "state/ui";
/** Components */
import  { Footer } from "components/Footer/Footer.component";
import Education from "components/Education/Education.component";
import Header from "components/Header/Header.component";
import Home from "components/Home/Home.component";
import Work from "components/Work/Work.component";
/** Styles */
import styles from "components/App.module.scss"

function App() {
  
  /** UI state */
  const [ui, dispatchUI] = UIState();

  return (
    <UIContext.Provider value={{ui, dispatchUI}}>
      <div className={styles["container-wrapper"]} style={{background: ui.uiTheme === "light" ? "white" : "#161933"}}>
        <Header />
        <div className={styles.container}>
          <Routes>
            <Route path="/" element={<Home theme={ui.uiTheme}/>}/>
            <Route path="/work-experience" element={<Work theme={ui.uiTheme}/>} />
            <Route path="/education" element={<Education theme={ui.uiTheme}/>}/>
          </Routes>
        </div>
        <Footer theme={ui.uiTheme}/>
      </div>
    </UIContext.Provider>
  );
}

export default App;
