/** Types and constants */
import { CHANGE_UI_THEME, ADD_NOTIFICATION } from "./App.constants";
import { UIAction, UI, Notification } from "./App.types";
/** Utils */
import { getLocalStorageItem, setLocalStorageItem } from "../shared/shared.utils";

export const changeUITheme = (theme: string): UIAction => {
  /** Sets UI theme */
  let ui: UI = getLocalStorageItem("wlui", {});
  ui.uiTheme = theme;
  setLocalStorageItem("wlui", ui);
  return {
    type: CHANGE_UI_THEME,
    payload: theme || "light"
  }
};

/** Add a Feedback notification to the FeedbackContainer */
export const addNotification = (notification: Notification): UIAction => {
  return {
    type: ADD_NOTIFICATION,
    payload: notification
  }
}



