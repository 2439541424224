import { Notification } from "components/App.types";
/** External library */
// import { decode } from "js-base64";

/** Get value from a local storage custom Hook */
export const getLocalStorageItem = (key: string, initialValue: any) => {
  try {
    let item = checkLocalStorageItem(key) ? JSON.parse(localStorage.getItem(key) || "{}") : initialValue;
    return item;
  } catch (error) {
    return initialValue;
  }
}

/** Get value from a session storage custom Hook */
// export const getSessionStorageItem = (key: string, initialValue: any) => {
//   try {
//     /** The decode() method decodes a base-64 encoded string. js-base64 library is used.
//      * This method decodes a string of data which has been encoded by the encode() method.
//      */
//     let item = checkSessionStorageItem(key) ? JSON.parse(decode(sessionStorage.getItem(key) || "{}")) : initialValue;
//     return item;
//   } catch (error) {
//     return initialValue;
//   }
// }

/** Set value in a local storage custom Hook */
export const setLocalStorageItem = (key: string, value: Object) => {
  localStorage.setItem(key, JSON.stringify(value));
}

/** Remove item from the local storage */
export const removeLocalStorageItem = (key: string) => {
  localStorage.removeItem(key);
}

/** Check if an item exists in the local storage */
export const checkLocalStorageItem = (key: string) => {
  return localStorage.hasOwnProperty(key);
}

/** Check if an item exists in the session storage */
export const checkSessionStorageItem = (key: string) => {
  return sessionStorage.hasOwnProperty(key);
}

/** Clear the localStorage */
export const clearLocalStorageItem = () => {
  localStorage.clear();
}

/** Clear the sessionStorage */
export const clearSessionStorageItem = () => {
  sessionStorage.clear();
}

/** HTTP general warning message */
export const httpResponseWarningMessage = (intl: any, message?: string, type?: string): Notification => {
  return {
    type: type ||" WARNING",
    message: message || intl.formatMessage({ id: "HttpWarningMessage", defaultMessage: "This operation is currently not possible. Please try again later."}),
    delay: 5000,
    style: { zIndex: 5000 }
  }
}

/** Generates query parameters */
export const generateQueryString = (queryString: string, parameters: any[], add: boolean) => {
  /** Returns a URLSearchParams object instance */
  const query = new URLSearchParams(queryString);
  parameters.forEach((param: any) => {
    const key = Object.keys(param)[0];
    /* Add or remove a query string parameter conditionally */
    add && (typeof param[key] === "string" ? param[key].length > 0 : (param[key] ? true : false))
      ? query.set(key, encodeURIComponent(param[key]))
      : query.delete(key);
  });
  return query.toString();
}

/** Returns a sort parameter. Returns null if it doesn't exist in a query string */
export const getCurrentValue = (query: string): string | null => {
  /** Returns a URLSearchParams object instance */
  const _query = new URLSearchParams(query);
  /** Retrieves a sort parameter value or null*/
  return _query.get("sort");
};

/** Reduce filters */
export const reduceFilters = (filters: any, key: string) => {
  return Object.entries(filters).reduce((accumulator: any, [curKey, curValue]: any) => {
    if (curKey === "skip") {
      curValue = "0";
    }
    if (curKey !== key) { return {...accumulator, [curKey]: curValue}; } return accumulator; }, {})
}

/** Get an initial page. It would be useful on browser reload */
export const getInitialPage = (query: string) => {
  let current;
  /** Returns a URLSearchParams object instance */
  const _query = new URLSearchParams(query);
  const skip = _query.get("skip");
  const take = _query.get("take");
  if (skip && typeof parseInt(skip) === "number" && take && typeof parseInt(take) === "number") {
    current = parseInt(skip)/parseInt(take) + 1;
  }
  return current;
}

/** Converts number to a human readable file size */
export const formatBytes = (bytes: number) => {
  const sizes = ["Bytes", "KB", "MB"];
  const size = Math.floor(Math.log(bytes) / Math.log(1024));
  return bytes === 0 ? "0 Bytes" : (bytes / Math.pow(1024, size)).toFixed(2) + " " + sizes[size];
}
