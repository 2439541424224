/** React */
import { createContext, useReducer } from "react";
/** Types and Constants */
import { initialUIState, UIContextState, UIContextInterface, UI } from "components/App.types";
/** Reducers */
import AppReducer from "components/App.reducers";
/** Utils */
import { getLocalStorageItem } from "../shared/shared.utils";

/**  Manages UI state in SPA */
export const UIState = () => {
  const fixedUI: UI = getLocalStorageItem("wlui", {});
  let uiState = initialUIState;
  /** Add ui theme value from the localStorage if exists */
  if (fixedUI.hasOwnProperty("uiTheme") && typeof fixedUI.uiTheme === "string") {
    uiState.uiTheme = fixedUI.uiTheme;
  }
  return useReducer(AppReducer, uiState);
}

export const UIContext = createContext(UIContextState as UIContextInterface);
