import { useContext } from "react";
import { useNavigate } from "react-router-dom";
/** Contexts */
import { UIContext } from "state/ui"
/** Styles */
import styles from "./Header.module.scss"
import LogoLight from "assets/images/logo-light.png";
import LogoDark from "assets/images/logo-dark.png";
import { BsFillSunFill, BsFillMoonStarsFill } from "react-icons/bs"
/** Actions */ 
import { changeUITheme } from "components/App.actions";

const Header = () => {

  /** UI context */
  const { ui, dispatchUI } = useContext(UIContext);
  let navigate = useNavigate();

  const theme = ui.uiTheme;

  return <div className={`${styles.header} ${styles[theme]}`}>
    <div 
      className={styles["logo-div"]}
      style={{cursor: "pointer"}}
      onClick= {() => navigate(`/`)}
    >
      {ui.uiTheme === "light"
        ? <img src={LogoDark} alt="logo" width={100}/>
        : <img src={LogoLight} alt="logo" width={100}/>
      }
    </div>

    <div className={styles["nav-menu-drawer"]}>
      {/* <button className={styles["nav-menu-button"]}>
        <BsList color={ui.uiTheme === "light" ? "#4c4f77" : "white"}/>
      </button>  */}
    </div>

    <div className={styles["menu-list"]}>
      <div onClick={() => navigate(`/`)}>{"Home"}</div>
      <div onClick={() => navigate(`/work-experience`)}>{"Work experience"}</div>
      <div onClick={() => navigate(`/education`)}>{"Education"}</div>
    </div>
      
    <div className={styles["switch-wrapper"]}>
      <label className={styles.switch}>
        <input 
          type="checkbox" 
          onChange={() => dispatchUI(changeUITheme(ui.uiTheme === "light" ? "dark" : "light"))} 
          checked={ui.uiTheme === "light"} 
        />
        <span className={`${styles.slider} ${styles.round}`}>
          <BsFillSunFill className={styles.sun}/>
          <BsFillMoonStarsFill className={styles.moon}/>
        </span>
      </label>
    </div>
    
    {/* <div className={styles["login-wrapper"]}>
      <div className={styles["login-button"]}>
        <button style={{background: theme === "light" ? "#00c9c6" : "#e15345"}}>Login</button>
      </div>
      <div className={styles["icon-login"]}>
        <BsFillPersonFill/>
      </div>
    </div> */}

  </div>
}

export default Header;