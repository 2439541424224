import React from "react";
/** Styles */
import styles from "./Footer.module.scss"
import { AiOutlineMail } from "react-icons/ai";
import { BsLinkedin } from "react-icons/bs";

export const Footer:React.FC<{theme: string}> = ({theme}) => {
  const goToLinkedIn = () => {
    window.open("https://www.linkedin.com/in/elizabethtokareva", "_blank");
  } 
  const emailTo = () => {
    window.open(`mailto:contact@webliz.dev`);
  } 
  return <footer className={styles.footer} style={{background: theme === "light" ? "rgb(0, 201, 198)" : "#1f2240"}}>
    <div className={styles.wrapper}>
      <AiOutlineMail onClick={() => emailTo()}/>
      <BsLinkedin onClick={() => goToLinkedIn()}/>
    </div>
  </footer>
}