import React from "react";
/** Styles */
import styles from "./Education.module.scss"
import { GoPrimitiveDot } from "react-icons/go";


const Education:React.FC<{theme: string}> = ({theme}) => {

  return <>
    <div className={`${styles["slide-main"]} ${styles[`slide-main-${theme}`]}`}>
      <div className={styles.content}>
        <div style={{borderBottom: theme === "light" ? "1px solid #4c4f77" : " 1px solid white", marginBottom: 10, marginTop: 10}}>
          <div className={styles["job-header"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>UNIVERSITY OF LIMERICK 2013-2014</div>
          <div className={styles["job-title"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>HIGHER DIPLOMA IN SOFTWARE DEVELOPMENT / FULL TIME</div>
          <div className={styles["job-tech-stack"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Core modules</div>
            <div>
              <GoPrimitiveDot />
              <div>Java</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Web development</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Mobile Applications Development</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Infrastructure</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Database Systems</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Information Systems Development</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Information Modelling and Specifications</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Software Testing and Inspection</div>
            </div>
          </div>
          <div className={styles["job-responsibility"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Key Areas and Responsibilities</div>
            <div>
              <GoPrimitiveDot />
              <div>I am responsible for architecture, design and development of Frontend web applications</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Create, optimizing, testing and documenting architectures for front-end applications</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Creating guidelines and coding conventions: HTML, CSS, JavaScript/Typescript</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Review  the implementation of applications, following code standards and style guides</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Setting the workflows for teams (that guarantee everyone uses the same tooling). Leading frontend code reviews</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Evaluating new frameworks and tools</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Analyzing and building UI component libraries</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Analyzing and building UI component libraries</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Perfect understanding of fundamental web technologies such as HTTP, REST, AJAX and JSON</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Integration and design of RESTful APIs</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Maintaining and debugging production software systems</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Integration of anylythics system</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Experience in UI / UX design</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Unit Testing with Jest/React test renderrer</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Strong CSS/HTML skills. Experience building responsive applications across a variety of browsers and devices using modern CSS techniques</div>
            </div> 
            <div>
              <GoPrimitiveDot />
              <div>Working with Go</div>
            </div> 
          </div>
        </div>
        <div style={{borderBottom: theme === "light" ? "1px solid #4c4f77" : " 1px solid white", marginBottom: 10, marginTop: 10}}>
          <div className={styles["job-header"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>MOSCOW STATE TECHNICAL UNIVERSITY OF CIVIL AVIATION 2001 - 2006 / FULL TIME</div>
          <div className={styles["job-title"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>MASTER'S DEGREE IN APPLIED MATHEMATICS</div>
          <div className={styles["job-tech-stack"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Core modules</div>
            <div>
              <GoPrimitiveDot />
              <div>Mathematical analysis</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Algebra and analytic geometry</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Algorithmic languages and Software development</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Computer graphics</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Mathematical Modelling</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Game theory and operations research</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Visual design</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Data science</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Management engineering and planning</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
}

export default Education;