import React from "react";
/** Styles */
import styles from "./Home.module.scss"
import coverLight from "assets/images/cover1.png";
import coverDark from "assets/images/cover2.png";
import { FaReact, FaAngular, FaNodeJs, FaVuejs } from "react-icons/fa";
import { AiFillGithub } from "react-icons/ai";
import { GiVanillaFlower } from "react-icons/gi";
import { SiTypescript } from "react-icons/si";

const Home:React.FC<{theme: string}> = ({theme}) => {

  return <>
    <div className={`${styles["slide-main"]} ${styles[`slide-main-${theme}`]}`}>
      <div className={styles["image-wrapper"]}>
        {theme === "light" 
          ? <img src={coverLight} alt="cover" width={"60%"} height="auto" />
          : <img src={coverDark} alt="cover" width={"60%"} height="auto" />
        } 
      </div>
      <div className={styles["text-wrapper"]}>
        <div style={{paddingBottom: 100, color: theme === "light" ? "#4c4f77" : "white"}}>
          <span className={styles["text-heading"]} style={{fontFamily: "RocknRollOne-Regular", fontSize: 40}}>Elizabeth</span>
          <span style={{fontFamily: "RocknRollOne-Regular", fontSize: 30}}>Senior Software engineer</span>
          <div className={styles["text-icons"]}>
            <div style={{color: theme === "light" ? "rgb(210, 119, 150)" : "rgb(225, 83, 69)"}} >
              <FaReact className={styles.react}/>
              <FaAngular className={styles.angular}/>
              <FaNodeJs className={styles.node}/>
              <FaVuejs className={styles.vue}/>
              <AiFillGithub className={styles.git}/>
            </div>
          </div>
          <div className={styles["text-text"]}>
            {"I am passionate about software and web development. I prefer not ceasing to continue to learn and to keep interest in technology and I want to be developed with each challenge even further!"}
          </div>
        </div>
      </div>
    </div>
    <div 
      className={styles.divider}
      style={{background: theme === "light" ? "linear-gradient(to right bottom, transparent 50%, #d27796 0)" : "linear-gradient(to right bottom, transparent 50%,#4c4f77 0)"}}
    >
    </div>
    <div 
      className={`${styles["slide-introduction"]} ${styles[theme]}`} 
      style={{background: theme === "light" ? "#d27796" : "#4c4f77"}}
    >
      <div style={{paddingBottom: 100, color: theme === "light" ? "#4c4f77" : "white", padding: 50}}>
        <div className={styles["text-info"]}>
          {`I have 8+ years experience with React`} <FaReact className={styles.react}/> {`Angular`} <FaAngular className={styles.angular}/> 
          {` VueJs`} <FaVuejs className={styles.vue}/> {`NodeJs`} <FaNodeJs className={styles.node}/> {` Vanilla JS`} <GiVanillaFlower className={styles.node}/> 
          {` and Typescript`} <SiTypescript className={styles.node}/> 
        </div>
        <div className={styles["text-info"]}>
          {"I love to mentor and help other developers. Many people get into development looking at my motivation and my shining eyes when I talk about my work"}
        </div>
        <div className={styles["text-info"]}>
          {"I have strong knowledges of frontend Architecture, design patterns, HTML/CSS design and REST API's. I can build a web application from a scratch and to improve or validate any frontend project"}
        </div>
        <div className={styles["text-info"]}>
          {"I have been studying in University of Limerick, Ireland and I have completed a Higher Diploma in Software Development course and obtained Upper Second-Class Honours (2.1)"}
        </div>
        <div className={styles["text-info"]}>
          {"I speak 3 languages - english, german and russian"}
        </div>
      </div>
    </div>
    <div 
      className={`${styles["slide-learning"]} ${styles[theme]}`}
      style={{background: theme === "light" ? "#ffe3f2" : "#1f2240"}}
    >
    </div>
  </>
}

export default Home;