import React from "react";
/** Styles */
import styles from "./Work.module.scss"
import { GoPrimitiveDot } from "react-icons/go";
import { BsThreeDots } from "react-icons/bs";


const Work:React.FC<{theme: string}> = ({theme}) => {

  return <>
    <div className={`${styles["slide-main"]} ${styles[`slide-main-${theme}`]}`}>
      <div className={styles.content}>
        <div style={{borderBottom: theme === "light" ? "1px solid #4c4f77" : " 1px solid white", marginBottom: 10, marginTop: 10}}>
          <div className={styles["job-header"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>Meplato GmbH JUNE 2019 untill now</div>
          <div className={styles["job-title"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>SENIOUR SOFTWARE ENGINEER</div>
          <div className={styles["job-tech-stack"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Tech Stack</div>
            <div>
              <GoPrimitiveDot />
              <div>React & Redux</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Typescript</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>HTML & CSS3</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Figma</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Docker</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Tailwindcss</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Vite JS</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>GitHub</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Next.js</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>AngularJS, Grunt</div>
            </div>
          </div>
          <div className={styles["job-responsibility"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Key Areas and Responsibilities</div>
            <div>
              <GoPrimitiveDot />
              <div>I am responsible for architecture, design and development of Frontend web applications</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Create, optimizing, testing and documenting architectures for front-end applications</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Creating guidelines and coding conventions: HTML, CSS, JavaScript/Typescript</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Review  the implementation of applications, following code standards and style guides</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Setting the workflows for teams (that guarantee everyone uses the same tooling). Leading frontend code reviews</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Evaluating new frameworks and tools</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Analyzing and building UI component libraries</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Analyzing and building UI component libraries</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Perfect understanding of fundamental web technologies such as HTTP, REST, AJAX and JSON</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Integration and design of RESTful APIs</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Maintaining and debugging production software systems</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Integration of anylythics system</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Experience in UI / UX design</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Unit Testing with Jest/React test renderrer</div>
            </div>  
            <div>
              <GoPrimitiveDot />
              <div>Strong CSS/HTML skills. Experience building responsive applications across a variety of browsers and devices using modern CSS techniques</div>
            </div> 
            <div>
              <GoPrimitiveDot />
              <div>Working with Go</div>
            </div> 
          </div>
        </div>
        <div style={{borderBottom: theme === "light" ? "1px solid #4c4f77" : " 1px solid white", marginBottom: 10, marginTop: 10}}>
          <div className={styles["job-header"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>SynerTrade SES AG JUNE 2018 - JUNE 2019</div>
          <div className={styles["job-title"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>TECH LEAD/JUNIOR FRONTEND ARCHITECT IN TECHNOLOGY RESEARCH AND INNOVATION DIVISION</div>
          <div className={styles["job-tech-stack"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Tech Stack</div>
            <div>
              <GoPrimitiveDot />
              <div>Angular and Angular JS</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>JAVA</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>HTML & CSS3</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Node.js</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Docker</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>GitHub</div>
            </div>
          </div>
          <div className={styles["job-responsibility"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Key Areas and Responsibilities</div>
            <div>
              <GoPrimitiveDot />
              <div>Development of technology-driven prototypes</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Development of applications up to the initial product maturity (MVP)</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Leading and potentially implementing all frontend projects in Leipzig</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Management of the frontend architecture Development of the platform Accelerate and other products of sustainable development (including market research)</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Support for the development strategy of the Technology Innovation division for the frontend area</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Support of all internal developers in frontend architecture, e.g. by participating in the Technology Leadership Workshop</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Mentoring of new frontend developers in Leipzig</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Technology integrations</div>
            </div>
          </div>
        </div>
        <div style={{borderBottom: theme === "light" ? "1px solid #4c4f77" : " 1px solid white", marginBottom: 10, marginTop: 10}}>
          <div className={styles["job-header"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>Travelxite GmBh SEPTEMBER 2017 -
JUNE 2018</div>
          <div className={styles["job-title"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>TEAM LEAD FULL STACK SOFTWARE ENGINEER</div>
          <div className={styles["job-tech-stack"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Tech Stack</div>
            <div>
              <GoPrimitiveDot />
              <div>Angular</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Node.js</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>HTML & CSS3</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Node.js</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Docker</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Bitbuchet, Jira, Confluense</div>
            </div>
          </div>
          <div className={styles["job-responsibility"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Key Areas and Responsibilities</div>
            <div>
              <GoPrimitiveDot />
              <div>Angular projects lead and development</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Node.js projects lead and development.</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Leading and potentially implementing all frontend projects in Leipzig</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>HTML / CSS design using UI/UX designer's screens</div>
            </div>
          </div>
        </div>
        <div style={{borderBottom: theme === "light" ? "1px solid #4c4f77" : " 1px solid white", marginBottom: 10, marginTop: 10}}>
          <div className={styles["job-header"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>Dibomedia GmBh AUGUST 2014
SEPTEMBER 2017</div>
          <div className={styles["job-title"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>FULL STACK SOFTWARE DEVELOPER</div>
          <div className={styles["job-tech-stack"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Tech Stack</div>
            <div>
              <GoPrimitiveDot />
              <div>Angular and AngularJS, Bower, Grunt, Karma, connect</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Vanilla JS, Bootstrap</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>MongoDB/Mongoose, MySQL</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Node.js, Ruby on Rails, XOJO, Socket.io</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Docker</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Bitbuchet, Jira, Confluense</div>
            </div>
          </div>
          <div className={styles["job-responsibility"]} style={{color: theme === "light" ? "#4c4f77" : "white"}}>
            <div className={styles.header}>Key Areas and Responsibilities</div>
            <div>
              <GoPrimitiveDot />
              <div>Angular, AngularJS and Node.js web application design and development</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>
                Configuring and managing the HP ProLiant Micro Server as a copy of the real server (Ubuntu Linux). 
                Using its virtual machines for testing, developing and for operating systems installation (which can be used by all employees, using a remote desktop)
              </div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Set up an automated, bulletproof database backup solution (FTP, shell script)</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Continuous integration set up (Jenkins)</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Zabbix monitoring implementation. Displaying services states on Zabbix overview</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Master Slave Replication in MySQL has been implemented on the server</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Ha proxy configuration changing/improving</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Jenkins, Confluence, Jira, Hipchat, Bitbucket installation, configuration and managing</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Channel manager parse method implementation (Ruby on Rails)</div>
            </div>
            <div>
              <GoPrimitiveDot />
              <div>Jenkins, Confluence, Jira, Hipchat, Bitbucket installation, configuration and managing</div>
            </div>
          </div>
        </div>
        <div style={{color: theme === "light" ? "#4c4f77" : "white"}}> 
          <BsThreeDots /> {"Full CV / Reference letters available on request"}
        </div>
      </div>
    </div>
  </>
}

export default Work;